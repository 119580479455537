import Vue from "vue";
import App from "./App.vue";

/* Import the firebase SDK and extend with firestore */
import firebase from "firebase";

import vuetify from "./plugins/vuetify";
import router from "./router";
require("firebase/firestore");

/* Paste your firebase configuration below */
const config = {
  apiKey: "AIzaSyAF9UlSoCobjqZSj8RXWrnVSZ3cJmrYnYk",
  authDomain: "cheersat7.firebaseapp.com",
  databaseURL: "https://cheersat7.firebaseio.com",
  projectId: "cheersat7",
  storageBucket: "cheersat7.appspot.com",
  messagingSenderId: "328541190268",
  appId: "1:328541190268:web:23cc3052e9a94b32bbb1f9",
  measurementId: "G-E2L5CDQLML"
};

/* Initialize firebase with your configuration */
firebase.initializeApp(config);

/* Bind firebase to your Vue instance */
Vue.prototype.$firebase = firebase;

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount("#app");
