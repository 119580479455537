<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" />
    <InstaCelebrationCategories />
  </div>
</template>

<script>
// @ is an alias to /src
import InstaCelebrationCategories from "@/components/InstaCelebrationCategories.vue";

export default {
  name: "Home",
  components: {
    InstaCelebrationCategories
  }
};
</script>
